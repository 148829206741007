import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 80px;
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  & button {
    margin-left: 8px;
  }
`;

export const Menu = styled.button`
  margin-right: 16px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  & > svg {
    font-size: 28px;
    color: var(--primary-text-color);
    transition: 0.2s;

    &:hover {
      color: var(--white);
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 60px;
  object-fit: contain;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
`;

export const InputSearch = styled.input`
  border-radius: 20px;
  border: 1px solid var(--input-border-blurred);
  color: var(--primary-text-color);
  width: 250px;
  height: 30px;
  padding: 10px;
  transition: 0.2s;

  &:focus {
    width: 350px;
    border: 1px solid var(--primary-text-color);
  }
`;

export const ImageUser = styled.img`
  border: 1px solid var(--primary-text-color);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  object-fit: contain;
`;
