import styled from "styled-components";
import ButtonLayout from "../../components/Button";

interface IButton {
  background?: string;
  wide?: string;
}

interface ISpacing {
  jContent?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;

  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 60px;
  padding: 10px 20px;
  width: 100%;

  h2 {
    color: var(--white);
  }

  & > div:nth-child(2) {
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    .filters {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  background: var(--form-background);
  border-radius: 10px;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;

  label {
    color: var(--white);
    font-size: 18px;
    margin-left: 10px;
  }

  span {
    color: var(--warning);
    font-size: 1.2rem;
    margin-left: 4px;
    margin-right: 20px;
  }

  textarea {
    background: transparent;
    border: 2px solid var(--primary);
    border-radius: 10px;
    color: var(--primary);
    margin-top: 10px;
    max-height: 250px;
    max-width: 100%;
    min-height: 200px;
    min-width: 80%;
    padding: 10px;
    width: 100%;

    &:focus {
      box-shadow: 0 0 6px var(--primary);
    }
  }
`;

export const Row = styled.div<ISpacing>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.jContent ? props.jContent : "flex-start"};

  & > div:nth-of-type(2) > button:nth-of-type(2) {
    margin-right: 0;
  }

  @media (max-width: 599px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & select {
      border: 1px solid var(--primary);
      color: var(--primary);
      width: 100%;
      margin-top: 16px;
    }

    & .upload-label {
      width: 100% !important;
      margin-bottom: 16px;
    }

    & .upload-label + div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & button {
        width: 45%;
      }
    }
  }

  label {
    color: var(--white);
    font-size: 18px;
  }

  span {
    color: var(--warning);
    font-size: 1.2rem;
    margin-left: 4px;
    margin-right: 20px;
  }

  select {
    border: 2px solid var(--primary);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--primary);
    margin-right: 10px;
    padding: 4px 8px;
  }

  & > div:first-of-type {
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: var(--primary);
    height: 40px;
    padding: 8px 12px;
    width: auto;
    min-width: 30%;
  }

  & > div:last-of-type > span > svg {
    color: var(--primary);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Button = styled(ButtonLayout)<IButton>`
  background: ${(props) => props.background};
  align-self: flex-end;
  color: var(--black);
  font-weight: 600;
  margin-right: 16px;
  padding: 0;
  width: ${(props) => (props.wide ? props.wide : "130px")};
  white-space: nowrap;

  label {
    color: var(--black);
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    padding: 8px 30px;
  }

  input {
    display: none;
  }
`;

export const DivZap = styled.a`
  position: fixed;
  z-index: 1;
  display: inline;
  bottom: 70px;
  background-color: #10c044;
  border-radius: 50%;
  right: 30px;
  animation: shadow-pulse 1.2s infinite;
  width: 70px;
  height: 70px;

  @media (max-width: 599px) {
    bottom: 50px;
  }

  @keyframes shadow-pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(40, 167, 26, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(40, 167, 26, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(40, 167, 26, 0);
    }
  }

  span {
    background-color: var(--warning);
    color: #ffffff;
    font-weight: bold;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    font-size: 13px;
    top: -1px;
    border-radius: 50%;
    text-align: center;
  }
`;

export const Image = styled.img`
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-top: -1px;
  margin-left: 1px;
`;
