import Provider from "./config";

export const getIndicationsOpen = async (page: any, id: any, filter: any) => {
  try {
    const response = await Provider.get("/entidades", {
      params: {
        page: page,
        // classe: filter.classe,
        idIndicador: id,
        inativo: true,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar indicações abertas.");
  }
};

export const getIndicationsClosed = async (page: any, id: any) => {
  try {
    const response = await Provider.get("/entidades", {
      params: {
        page: page,
        idIndicador: id,
        inativo: false,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar indicações fechadas.");
  }
};
