import styled from "styled-components";

interface SelectProps {
  width: "xsm" | "sm" | "md" | "lg" | "xlg";
  fullwidth: boolean;
  error?: string;
  bold?: boolean;
  validateError?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  text-overflow: none;
  width: 100%;
  white-space: nowrap;
`;

export const Label = styled.label<SelectProps>`
  color: ${(props) =>
    props.validateError ? "var(--warning)" : "var(--white)"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: ${(props) => (props.bold ? "500" : "300")};
  margin-bottom: 4px;
  text-overflow: none;
  width: auto;
  white-space: nowrap;
`;

export const Select = styled.select<SelectProps>`
  background-color: var(--form-background);
  border-radius: 6px;
  border: ${({ error }) =>
    error || error?.length
      ? "1px solid var(--warning)"
      : "1px solid var(--primary)"};
  color: var(--primary);
  min-height: 35px;
  padding: 0px 8px 0px 8px;
  text-overflow: none;
  white-space: nowrap;
  width: 100%;

  &:focus {
    border: 1px solid var(--primary);
    box-shadow: 0 0 6px var(--primary-lighter);
  }

  & > option {
    appearance: none;
  }

  & > option:hover {
    background: var(--form-selected) !important;
    color: red !important;
  }
`;

export const Error = styled.p`
  margin-top: 4px;
  color: var(--warning);
`;
