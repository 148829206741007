import styled from "styled-components";
import ButtonLayout from "../../components/Button";
import Calendar from "../../assets/images/Calendar.png";
import ArrowDown from "../../assets/images/down-arrow.png";
import Check from "../../assets/images/check.png";
import Close from "../../assets/images/close.png";

interface IButton {
  background?: string;
  wide?: string;
}

interface IWidth {
  jContent?: string;
  aItems?: string;
  wide?: string;
  center?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;

  & .balance > div:nth-of-type(2) > span {
    min-height: 32px;
    min-width: 50px;
  }

  & > div:nth-of-type(4)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(4)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(4)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }

  @media (max-width: 1134px) {
    & > div:nth-of-type(1) > div:nth-of-type(2) {
      justify-content: flex-end;
    }

    & > .filters {
      flex-direction: column;
    }
  }

  @media (max-width: 657px) {
    & .balance {
      flex-direction: column;
    }
    & .balance > div {
      width: 100%;
    }
  }

  @media (max-width: 518px) {
    .balance > div {
      flex-direction: column;
    }
  }

  @media (max-width: 480px) {
    & .balance > div {
      flex-direction: column;
      align-items: center;
    }
    & .balance > div > label {
      margin-bottom: 10px;
      margin-right: 0;
    }
    & .balance > div > .balanceValue > p {
      margin-bottom: 4px;
    }
    & .balance > div:nth-of-type(2) > span {
      margin-left: auto;
    }
    & .balance > div:nth-of-type(2) > button {
      margin-right: 0;
    }
  }
`;

export const Content = styled.div`
  background-color: var(--form-background);
  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  color: var(--white);
  margin-top: 20px;
  overflow: auto;
  padding: 30px 10px 20px 10px;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const Title = styled.h2`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 60px;
  width: 100%;

  .loading > span {
    min-height: 32px;
    min-width: 40px;
  }

  .loading {
    float: right;
    justify-content: flex-end;
    margin-left: auto;
    width: 100%;
  }

  .loading > div {
    margin-right: 0;
  }

  @media (max-width: 1384px) {
    & > div:nth-of-type(2) > button:nth-of-type(4) {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    .filters {
      width: 100%;
    }
  }

  @media (max-width: 989px) {
    .filters {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 753px) {
    .filters {
      justify-content: flex-start;

      & select {
        width: 100%;
        margin-top: 16px;
        margin-right: 0;
      }

      & button {
        width: 100%;
        margin-top: 16px;
        margin-right: 0;
      }
    }
    .loading {
      flex-wrap: wrap;

      & button {
        margin-bottom: 10px;
      }

      & button:nth-of-type(4) {
        margin-right: 16px !important;
      }
    }
  }

  @media (max-width: 730px) {
    .buttonOrder {
      margin-left: 10px;
    }
    .buttonsOrder {
      flex-direction: row;

      & > button:nth-of-type(1) {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 546px) {
    & button {
      width: 45%;
      min-width: 160px;
    }

    .loading {
      & > .buttonsAction {
        justify-content: space-between;
      }
      & > div > button,
      & button:nth-of-type(4) {
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 482px) {
    & label {
      margin-right: 0;
      width: 100%;
    }

    & .link {
      margin-top: 10px;
      width: 100%;
    }
  }

  @media (max-width: 465px) {
    & .loading {
      justify-content: flex-start;
    }

    & .buttonOrder {
      margin-left: 10px !important;
      width: auto;
    }

    & .buttonsOrder {
      /* flex-direction: column; */
      flex-wrap: wrap;
    }
  }

  @media (max-width: 419px) {
    & .link {
      align-items: flex-start;
      padding-left: 10px;
    }

    & div {
      align-items: flex-start !important;
    }
  }

  @media (max-width: 410px) {
    & .buttonsOrder {
      flex-direction: column;
    }
  }

  @media (max-width: 330px) {
    .filters {
      flex-direction: column;
    }
  }
`;

export const Row = styled.div<IWidth>`
  display: flex;
  align-items: ${(props) => (props.aItems ? props.aItems : "center")};
  padding: 10px 20px;
  width: ${(props) => (props.wide ? props.wide : "100%")};

  .rowBalanceValue {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }

  .balanceValue {
    margin-right: 20px;
  }

  label {
    color: var(--white);
    font-size: 22px;
    margin-right: 60px;
  }

  .buttonOrder {
    background: transparent;
    padding: 0;
  }

  p {
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
  }

  p:first-child {
    margin-right: 10px;
  }

  .primary {
    color: var(--primary-lighter);
  }

  & > div .small {
    font-size: 14px;
    margin-right: 10px;
    width: auto;
  }

  & > span > svg {
    color: var(--primary);
    font-size: 26px;
    animation: spin 1s linear infinite;
  }

  & > form {
    display: flex;
    flex-wrap: wrap;
  }

  & > form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & > input[type="search"] {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 95.2%;
      border: 1px solid var(--primary-lighter);
      color: var(--primary);

      &:focus {
        box-shadow: 0 0 6px var(--primary-lighter);
      }
    }

    & > .small {
      width: 70px;
    }

    & > input {
      border: 1px solid var(--primary-lighter);
      border-radius: 6px;
      margin-top: 4px;
      padding: 6px 8px 4px 4px;
      width: 120px;
    }

    & > input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 4px;
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
    }

    & > input[type="search"]::-webkit-search-decoration,
    & > input[type="search"]::-webkit-search-results-button,
    & > input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      appearance: none;
    }

    & > input[type="search"]::-webkit-calendar-picker-indicator {
      opacity: 0;
      display: none;
    }

    option {
      text-transform: capitalize;
    }
  }

  & input {
    border: 1px solid var(--muted);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--white);
    font-size: 10px;
    margin-right: 10px;
    padding: 2px 4px;
  }

  & > form:nth-of-type(1) > div > label {
    color: var(--primary);
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    margin-right: 0;
  }

  & input::placeholder {
    color: var(--primary);
  }

  & input[type="date"] {
    background-image: url(${Calendar});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 95%;
    border: 1px solid var(--primary-lighter);
    color: var(--primary);
    min-height: 27px;
    min-width: 100px;
    padding: 4px 10px 4px 0;

    &:focus {
      box-shadow: 0 0 6px var(--primary-lighter);
    }
  }

  /* & input[type="date"] {
    background-image: url(${Close});
    background-repeat: no-repeat;
    background-position: 86%;
    background-size: 10px;
  } */

  & input[type="date"]::-webkit-inner-spin-button,
  & input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }

  & > form:nth-of-type(1) > div > button {
    background: transparent;
    margin-right: 0;
    margin-left: -30px;

    & > svg {
      color: var(--white);
      font-size: 16px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 787px) {
    .balanceValue {
      margin-right: 10px;
    }
    .balanceValue > p {
      white-space: nowrap;
    }

    .loading {
      flex-direction: column;
    }

    & > form,
    .buttonsOrder {
      display: flex;
      align-items: baseline;
      margin-top: 10px;
    }

    & > form > div {
      align-items: center;

      & > label {
        align-items: baseline !important;
      }
    }

    .rowBalanceValue,
    & > form,
    .buttonsOrder {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;

      &::-webkit-scrollbar {
        display: block;
        height: 6px;
        width: 100%;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border: 1px solid var(--input-border-blurred);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        border-radius: 20px;
        padding: 6px 0;
      }
    }
  }

  @media (max-width: 636px) {
    & > form {
      & > div {
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 546px) {
    & > form,
    .buttonsAction {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      button {
        width: 47% !important;
      }
    }
    & > form > div {
      justify-content: space-between;
      width: 48%;
      max-width: 50%;

      & > input,
      & > label > input {
        margin-right: 0;
        width: 100% !important;
      }
      & > label,
      & > label > input {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 465px) {
    & > div > .small {
      min-width: 48%;
      width: 50%;
    }
  }

  @media (max-width: 419px) {
    & > form:nth-of-type(1) > div {
      & > input[type="search"],
      & > input[type="number"] {
        width: 130px;
      }

      & > input[type="search"]::-webkit-search-cancel-button {
        right: 10px;
      }
    }
  }

  @media (max-width: 346px) {
    & > form {
      flex-direction: column;

      & > div {
        min-width: 100%;
      }
    }
  }

  @media (max-width: 320px) {
    & > .buttonsAction > button {
      width: 100% !important;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    background: var(--primary);
    margin-top: 10px;
    height: 1px;
    width: 100%;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }

  .datalist {
    background: var(--form-background) url(${ArrowDown});
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 10px;
  }

  .datalist::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  & > input[type="search"]::-webkit-search-cancel-button {
    position: relative;
    right: 4px;
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background: url(${Close});
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 10px;
    box-shadow: 0 0 10px 2px var(--primary);
    color: var(--primary);

    &:focus {
      box-shadow: 0 0 6px var(--primary-lighter);
    }
  }

  & > input[type="search"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    display: none;
  }

  .datalist::placeholder {
    color: var(--white);
  }

  .flex-end {
    border-top: 1px solid var(--white);
    justify-content: flex-end;
    margin-top: 14px;
    padding: 10px 16px;

    & :nth-child(2) {
      margin-right: 0;
    }
  }

  .input-group {
    display: flex;
    align-content: stretch;
    align-items: baseline;
    height: 30px;
    margin-bottom: 6px;

    & > span {
      border: 1px solid var(--primary);
      color: var(--primary);
      margin-left: 8px;
    }
  }

  .input-group > input {
    flex: 1 0 auto;
    /* width: 122px; */
  }

  .input-group-addon {
    border: 1px solid var(--muted);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-left: 8px;
    padding: 6px;
    width: 52px;
  }

  .input-trailing {
    align-items: center;
    margin-bottom: 0;
  }

  select,
  input {
    background: var(--form-background);
    border: 1px solid var(--primary);
    border-radius: 6px;
    color: var(--primary);
    font-size: 12px;
    padding: 4px;
    height: 35px;
  }

  select {
    width: 154px;
  }

  input[type="number"],
  input[type="text"],
  .large {
    width: 250px;
  }

  input[type="checkbox"] {
    appearance: none;
    border: 1px solid var(--white);
    border-radius: 0;
    margin-right: 6px;
    height: 16px;
    width: 16px;
  }

  input[type="checkbox"]:checked {
    background: var(--primary) url(${Check});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 1px solid var(--primary);
  }

  .small {
    width: 90px;
  }

  select:focus,
  input:focus {
    box-sizing: border-box;
    outline: 2px solid var(--primary-lighter);
    outline-offset: -2px;
  }

  & > .indicadores {
    border: 1px solid var(--white);
    border-radius: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 6px;
    width: 300px;
  }

  & > .indicadores > label > .switch-wrapper {
    display: inline-block;
    height: 26px;
    width: 52px;
    margin-right: 6px;
    position: relative;
  }

  & > .indicadores > label > .switch-wrapper > input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  & > .indicadores > label > .switch-wrapper > .switch-button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #475569;
  }

  & > .indicadores > label > .switch-wrapper > .switch-button::before {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    transition: 0.4s all ease;
    background-color: #ffffff;
  }

  & > .indicadores > label > .switch-wrapper > input:checked + .switch-button {
    background-color: var(--primary);
  }

  &
    > .indicadores
    > label
    > .switch-wrapper
    > input:checked
    + .switch-button::before {
    transform: translateX(26px);
  }

  @media (max-width: 599px) {
    width: 100%;

    .input-group {
      height: 40px;
    }

    .flex-end {
      justify-content: center;
    }

    .large {
      width: 100%;
    }
  }

  @media (max-width: 419px) {
    & > input[type="search"]::-webkit-search-cancel-button {
      right: 14px;
    }
  }

  @media (max-width: 370px) {
    .input-group {
      & > input[type="number"] {
        width: 80%;
      }
    }
    .indicadores {
      width: 90%;
    }
  }

  @media (max-width: 322px) {
    .flex-end {
      flex-direction: column;
      & > button {
        margin-right: 0;
        width: 100%;
      }

      & > button:nth-of-type(1) {
        margin-bottom: 8px;
      }
    }
  }

  @media (max-width: 305px) {
    .input-group {
      & > input[type="number"] {
        width: 70%;
      }
    }
  }
`;

export const RowButtonOrder = styled(Row)`
  justify-content: ${(props) =>
    props.jContent ? props.jContent : "flex-start"};
  flex-wrap: wrap;
  max-width: 100%;

  & > div {
    display: flex;
    width: 50%;
  }

  & > div:nth-of-type(2) {
    justify-content: flex-end;
  }

  @media (max-width: 989px) {
    overflow-x: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;

    &::-webkit-scrollbar {
      display: block;
      height: 6px;
      width: 100%;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid var(--input-border-blurred);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 20px;
      padding: 6px 0;
    }
  }

  @media (max-width: 730px) {
    flex-direction: column;

    & > div,
    & > div:nth-of-type(2) {
      flex-wrap: wrap;
      align-items: flex-start !important;
      justify-content: flex-start;
      padding-left: 0;
      width: 100%;
    }
  }

  @media (max-width: 546px) {
    padding: 10px;

    & > div:nth-of-type(2) {
      justify-content: center;
      padding-left: 20px;

      & > div {
        margin-left: auto;
        margin-right: auto;
      }
    }

    & button {
      min-width: min-content !important;
    }

    & button > p {
      margin-right: 10px !important;
    }
  }

  @media (max-width: 419px) {
    & > div,
    & > div:nth-of-type(2) {
      align-items: flex-start;
      padding-left: 0;
      width: 100%;

      & > div {
        margin-bottom: 8px;
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 386px) {
    align-items: flex-start;

    & > div:nth-of-type(2) {
      & > div {
        margin-left: 0;
      }
    }

    & button > p {
      margin-bottom: 8px;
      text-align: left;
      width: auto;
    }
  }
`;

export const RowModal = styled.div<IWidth>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.center ? props.center : "flex-end")};
  margin-top: 10px;
  padding: 0 16px;
  width: 100%;

  span {
    border: 1px solid var(--muted);
    border-radius: 10px;
    padding: 6px;
  }

  @media (max-width: 599px) {
    align-items: baseline;

    & > div > input:not([type="checkbox"]),
    & select {
      margin-bottom: 16px;
    }

    & > div > input {
      width: 100%;
    }

    & select {
      width: 50%;
    }
  }
`;

export const Button = styled(ButtonLayout)<IButton>`
  background: ${(props) => props.background};
  align-self: flex-end;
  color: var(--black);
  font-weight: 600;
  margin-right: 16px;
  width: ${(props) => (props.wide ? props.wide : "130px")};
  white-space: nowrap;
  scroll-snap-align: start;

  & > span > svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Form = styled.form``;

export const Footer = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--opacity-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  width: 100%;

  @media (max-width: 599px) {
    flex-direction: column;
  }

  @media (max-width: 348px) {
    padding: 8px 0;
  }
`;

export const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;

  select {
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--primary);
    margin-right: 10px;
    padding: 4px 8px;

    &:focus {
      box-shadow: 0 0 6px var(--primary-lighter);
    }
  }

  & > p {
    color: var(--primary-lighter);
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;

    & > b {
      /* font-weight: 500; */
      margin-left: 4px;
    }
  }
`;

export const PaginateContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: 599px) {
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
  }

  & .active-page-item {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--black);
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    @media (max-width: 599px) {
      margin-top: 16px;
      overflow: auto;
    }

    li {
      padding: 4px 8px;
      margin: 0 2px;
      cursor: pointer;
      color: var(--primary);
      background-color: transparent;
      transition: 0.2s;
      border-radius: 2px;
      border: 1px solid var(--primary);

      &:hover {
        background-color: var(--primary);
        border: 1px solid var(--primary);
        color: var(--black);
      }

      &:active {
        background-color: var(--primaryDarker);
      }
    }
  }

  @media (max-width: 414px) {
    ul > li {
      padding: 0 6px;
    }
  }

  @media (max-width: 375px) {
    ul > li {
      padding: 0 4px;
    }
  }
`;
