import React, { useEffect, useState } from "react";
import { Title } from "../Home/styles";
import {
  Button,
  ButtonCard,
  ButtonTrash,
  Card,
  Column,
  Container,
  Content,
  Row,
  RowButton,
  RowButtonAlert,
  TitleCard,
} from "./styles";
import { BiTrash } from "react-icons/bi";
import { useFormModal } from "../../contexts/formModal";
import PriceAlertModal from "./priceAlertModal";
import { AiOutlineLoading } from "react-icons/ai";
import { ContainerModal } from "../Training/style";
import { FiEdit } from "react-icons/fi";
import { useAuth } from "../../contexts/auth";
import Toast from "../../components/Toast";
import useFetch from "../../hooks/useFetch";
import {
  DivCard,
  Footer,
  PaginateContainer,
  RowButtonOrder,
  SelectedContainer,
} from "../Orders/style";
import { IResponse } from "../Orders";
import { getWhiteListOnlyNames } from "../../services/whiteList";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import ReactPaginate from "react-paginate";
import { BASE_URL } from "../../environment/stores";
import {
  deleteAlert,
  editAlert,
  getAlerts,
  getExecuted,
  getFields,
} from "../../services/alert";
import AsyncSelect from "react-select/async";
// import { AsyncSelect } from "../../components/LookupInput/styles";

interface IPriceAlert {
  title: string;
  endpoint: string;
  radioSelect?: boolean;
  multipleSelect?: boolean;
  filters: object;
}

const PriceAlert: React.FC<IPriceAlert> = ({
  title,
  endpoint,
  radioSelect,
  multipleSelect,
  filters,
}) => {
  const { openModal, closeModal } = useFormModal();
  const { user } = useAuth();
  const provider = useFetch({ endpoint });
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [alertsPending, setAlertsPending] = useState<IResponse | null>(null);
  const [alertsExecuted, setAlertsExecuted] = useState<IResponse | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [colsOrdersClosed, setColsOrdersClosed] = useState<Array<IField>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);
  const [pending, setPending] = useState(true);
  const [filterPending, setFilterPending] = useState({
    coin: "",
  });
  const [filterExecuted, setFilterExecuted] = useState({
    coin: "",
  });
  const [coin, setCoin] = useState([]);
  const [coinExecuted, setCoinExecuted] = useState<any>([]);
  const [growing, setGrowing] = useState({
    item: "",
    asc: "",
  });

  // useEffect(() => {
  //   getTableFieldsAlerts();
  //   getAlertsExecuted();
  // }, []);

  useEffect(() => {
    if (filterPending.coin !== "") {
      getFilterAlerts(filterPending);
    } else if (filterExecuted.coin !== "") {
      getFilterAlerts(filterExecuted);
    } else {
      getTableFieldsAlerts();
      getAlertsExecuted();
    }
  }, [filterPending, filterExecuted]);

  const getFilterAlerts = (filters?: any) => {
    if (pending) {
      getTableFieldsAlerts(filters);
    } else {
      getAlertsExecuted(filters);
    }
  };

  const getTableFieldsAlerts = async (filters?: any) => {
    try {
      setLoading(true);
      const id = user?.id;
      if (id) {
        const result = await getFields(id);
        if (result) {
          const fields = [
            {
              campo: "Pessoa.email",
              editavel: false,
              nome: "Email",
              order: 7,
              required: true,
              tipo: "lookup",
            },
            {
              campo: "Pessoa.zCodigoTelegram",
              editavel: false,
              nome: "Telegram",
              order: 8,
              required: true,
              tipo: "lookup",
            },
          ];
          const _result = result.filter(
            (item: any) => item.nome !== "Valor atual"
          );

          const _resultPending = result.filter(
            (item: any) => item.nome !== "Data de Execução"
          );

          setCols([..._resultPending, ...fields]);
          setColsOrdersClosed([..._result, ...fields]);

          if (filters) {
            const alerts = await getAlerts(id, currentPage, filters);
            if (alerts) {
              const newCoin: any = [];
              const filterCoin = alerts.docs.map(
                (item: any) => item.alertObject.symbol
              );
              const _filterCoin = new Set(filterCoin);

              _filterCoin.forEach((item: any) => {
                newCoin.push(item);
              });

              const formatted = newCoin.map((item: any) => {
                return {
                  value: item,
                  label: item,
                };
              });

              setCoin(newCoin);
              setAlertsPending(alerts);
              setLoading(false);

              return formatted;
            }
          } else {
            const alerts = await getAlerts(id, currentPage);
            if (alerts) {
              const newCoin: any = [];
              const filterCoin = alerts.docs.map(
                (item: any) => item.alertObject.symbol
              );
              const _filterCoin = new Set(filterCoin);

              _filterCoin.forEach((item: any) => {
                newCoin.push(item);
              });

              const formatted = newCoin.map((item: any) => {
                return {
                  value: item,
                  label: item,
                };
              });

              setCoin(newCoin);
              setAlertsPending(alerts);
              setLoading(false);
              return formatted;
            }
          }
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
  };

  const getAlertsExecuted = async (filters?: any) => {
    try {
      setLoading(true);
      const id = user?.id;
      if (id) {
        if (filters) {
          const result = await getExecuted(id, currentPageClosed, filters);
          if (result) {
            const newCoin: any = [];
            const filterCoin = result.docs.map(
              (item: any) => item.alertObject.symbol
            );
            const _filterCoin = new Set(filterCoin);

            _filterCoin.forEach((item: any) => {
              newCoin.push(item);
            });

            setCoinExecuted(newCoin);
            setAlertsExecuted(result);
            setLoading(false);
          }
        } else {
          const result = await getExecuted(id, currentPageClosed);
          if (result) {
            const newCoin: any = [];
            const filterCoin = result.docs.map(
              (item: any) => item.alertObject.symbol
            );
            const _filterCoin = new Set(filterCoin);

            _filterCoin.forEach((item: any) => {
              newCoin.push(item);
            });

            setCoinExecuted(newCoin);
            setAlertsExecuted(result);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
  };

  const handleModal = (title: string, item?: any) => {
    if (user?.zCodigoTelegram === "") {
      Toast.show("Insira o código de telegram no perfil do usuário.");
    } else if (user?.zRecebeNotificacaoTelegram === false) {
      Toast.show("Habilite receber notificações telegram no perfil do usuário");
    } else {
      openModal({
        type: "confirm",
        title: title,
        wide: "340px",
        children: () => (
          <PriceAlertModal
            title={title}
            endpoint={endpoint}
            getTableFieldsAlerts={getTableFieldsAlerts}
          />
        ),
      });
    }
  };

  const handleModalDelete = () => {
    openModal({
      type: "confirm",
      title: "Excluir Alerta",
      children: renderDeleteContent,
    });
  };

  const renderDeleteContent = () => {
    return (
      <ContainerModal>
        <h3 style={{ marginTop: 2, marginBottom: 10, fontWeight: 400 }}>
          Tem certeza que deseja excluir?
        </h3>
        <p>Esta ação é irreversível.</p>
        <div>
          {loading ? (
            <span>
              <AiOutlineLoading />
            </span>
          ) : null}{" "}
          &nbsp;
          <Button
            mLeft={"0px"}
            wide={"150px"}
            className="danger"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
          <Button mLeft={"10px"} wide={"150px"} onClick={() => handleDelete()}>
            Excluir
          </Button>
        </div>
      </ContainerModal>
    );
  };

  const handleDelete = async () => {
    setLoading(true);
    const id = itensSelected();
    const idUser = user?.id;

    if (id && idUser) {
      try {
        const response = await deleteAlert(id, idUser);
        if (response) {
          // Toast.show("Excluído com sucesso.", "success");
          getTableFieldsAlerts();
          setLoading(false);
          closeModal();
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
    }
    setLoading(false);
  };

  const handleChangePage = (page: number) => {
    if (pending) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };

  const handleOrderCols = (value: string) => {
    if (value === "Par" || value === "Quantidade") {
      if (growing.item === value) {
        setGrowing({
          ...growing,
          item: growing.asc == "false" ? "" : value,
          asc: growing.asc == "false" ? "" : "false",
        });
      } else {
        setGrowing({
          ...growing,
          item: value,
          asc: "true",
        });
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const itensSelected = () => {
    const itens: any = [];

    if (alertsPending) {
      let docs = [...alertsPending?.docs];
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item == true) {
          itens.push(docs[index].id);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item);
        if (itemIndex >= 0) {
          docs.splice(itemIndex, 1);
        }
      });
      // setSelectedIndexes(selection)
    }

    if (itens.length > 0) {
      return itens;
    } else {
      Toast.show("Selecione o item que deseja excluir.");
    }
  };

  const checkSelectedIndexes = () => {
    const result = selectedIndexes.find((item) => item === true);
    return result;
  };

  const handleFilter = (key: string, field: string) => {
    if (pending) {
      setFilterPending({
        ...filterPending,
        [key]: field,
      });
    } else {
      setFilterExecuted({
        ...filterExecuted,
        [key]: field,
      });
    }
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    if (pending) {
      setFilterPending({ ...filterPending, coin: inputValue });
    }
    return inputValue;
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard className="filters">
        <RowButtonAlert>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setPending(true)}
          >
            <p className={pending ? "primary" : ""}>
              Pendente
              {alertsPending?.total ? `(${alertsPending.total})` : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setPending(false)}
          >
            <p className={!pending ? "primary" : ""}>
              Executado
              {alertsExecuted?.total ? `(${alertsExecuted.total})` : "(0)"}
            </p>
          </button>
        </RowButtonAlert>
      </DivCard>
      <DivCard className="filters">
        <Row jContent="space-between" padd="10px 20px">
          <div className="filter">
            <p className="primary">Filtros</p>
            {pending ? (
              <form autoComplete="off">
                <div>
                  {/* <AsyncSelect
                    maxMenuHeight={150}
                    value={coin}
                    loadOptions={getTableFieldsAlerts}
                    inputValue={filterPending.coin}
                    onInputChange={handleInputChange}
                    // onChange={handleFilter}
                    noOptionsMessage={() => "Nenhum item"}
                    placeholder=""
                    loadingMessage={() => "Carregando..."}
                  /> */}
                  <input
                    type="search"
                    name="myCoinPending"
                    id="myCoinPending"
                    list="coinPending"
                    autoComplete="chrome-off"
                    value={filterPending.coin ? filterPending.coin : ""}
                    placeholder="Todas Moedas"
                    onChange={(ev) => handleFilter("coin", ev.target.value)}
                  />
                  <datalist id="coinPending">
                    {coin
                      ? coin.map((item: any) => (
                          <option value={item}>{item}</option>
                        ))
                      : null}
                  </datalist>
                </div>
              </form>
            ) : (
              <form autoComplete="off">
                <div>
                  <input
                    type="search"
                    name="myCoinExecuted"
                    id="myCoinExecuted"
                    list="coinExecuted"
                    autoComplete="chrome-off"
                    value={filterExecuted.coin ? filterExecuted.coin : ""}
                    placeholder="Todas Moedas"
                    onChange={(ev) => handleFilter("coin", ev.target.value)}
                  />
                  <datalist id="coinExecuted">
                    {coinExecuted
                      ? coinExecuted.map((item: any) => (
                          <option value={item}>{item}</option>
                        ))
                      : null}
                  </datalist>
                </div>
              </form>
            )}
          </div>
          <RowButton className="buttons">
            <span>
              {loading ? (
                <>
                  <AiOutlineLoading /> &nbsp;
                </>
              ) : loadingFilter ? (
                <>
                  <AiOutlineLoading /> &nbsp;
                </>
              ) : null}
            </span>
            {pending ? (
              <div className="rowButton">
                {user?.inativo === false ? (
                  <Button
                    wide={"150px"}
                    onClick={() => handleModal("Criar Alerta")}
                  >
                    Novo
                  </Button>
                ) : null}
                <Button
                  wide={"150px"}
                  mLeft={user?.inativo === true ? "auto" : "10px"}
                  disabled={!checkSelectedIndexes()}
                  onClick={() => handleDelete()}
                >
                  Excluir
                </Button>
              </div>
            ) : null}
          </RowButton>
        </Row>
      </DivCard>
      <Content>
        <ListTable
          multipleSelect={pending ? multipleSelect : false}
          changeSelectedIndexes={setSelectedIndexes}
          fields={pending ? cols : colsOrdersClosed}
          handleOrderCols={handleOrderCols}
          growing={growing}
          onChangePage={handleChangePage}
          currentPage={pending ? currentPage : currentPageClosed}
          data={
            pending && alertsPending
              ? alertsPending.docs
              : !pending && alertsExecuted
              ? alertsExecuted.docs
              : []
          }
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={2}
            pageCount={
              pending && alertsPending
                ? alertsPending.totalPages
                : !pending && alertsExecuted
                ? alertsExecuted.totalPages
                : 1
            }
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
      {/* <Column border>
          <p>Alertas Criados</p>
          <Row>
            <Card>
              <div>
                <TitleCard>ETHUSDT</TitleCard>
                <p>
                  Tipo: <span>Preço ultrapassar</span>
                </p>
                <p>
                  Frequência: <span>Somente uma vez</span>
                </p>
                <Row>
                  <ButtonCard>$13.00</ButtonCard>
                  <div>
                    <ButtonTrash
                      onClick={() => handleModal("Editar Alerta")}
                      title="Editar"
                    >
                      <FiEdit />
                    </ButtonTrash>
                    <ButtonTrash
                      type="button"
                      title="Excluir"
                      onClick={() => handleModalDelete()}
                    >
                      <BiTrash />
                    </ButtonTrash>
                  </div>
                </Row>
              </div>
            </Card>
            <Card>
              <div>
                <TitleCard>BNBUSDT</TitleCard>
                <p>
                  Tipo: <span>Preço cair para</span>
                </p>
                <p>
                  Frequência: <span>Sempre</span>
                </p>
                <Row>
                  <ButtonCard>$10.00</ButtonCard>
                  <div>
                    <ButtonTrash
                      onClick={() => handleModal("Editar Alerta")}
                      title="Editar"
                    >
                      <FiEdit />
                    </ButtonTrash>
                    <ButtonTrash
                      type="button"
                      title="Excluir"
                      onClick={() => handleModalDelete()}
                    >
                      <BiTrash />
                    </ButtonTrash>
                  </div>
                </Row>
              </div>
            </Card>
          </Row>
        </Column> */}
    </Container>
  );
};

export default PriceAlert;
