import styled from "styled-components";

import InputLayout from "../../components/TextInput";
import SelectLayout from "../../components/SelectInput";

interface IRowProps {
  amount: number;
}

export const Input = styled(InputLayout)`
  background: var(--form-background);
  color: var(--primary);
  margin-bottom: 8px;

  & > input {
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--white);

    &:focus {
      border: 1px solid var(--primary);
      background: transparent;
      box-shadow: 0 0 6px var(--primary-lighter);
    }
  }
`;

export const Select = styled(SelectLayout)`
  margin-bottom: 8px;
`;

export const Row = styled.div<IRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div,
  & > select {
    width: ${(props) => props.amount}%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    & > div,
    & > select {
      width: 100%;
    }
  }
`;
