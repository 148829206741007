import styled from "styled-components";
import ArrowDown from "../../assets/images/down-arrow.png";
import ButtonLayout from "../../components/Button";
import Close from "../../assets/images/close.png";

interface IButton {
  background?: string;
  wide?: string;
}

export const Container = styled.div`
  width: 100%;

  & > div:nth-of-type(4)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(4)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(4)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;

  label {
    color: var(--white);
    font-size: 20px;
    margin-right: 60px;
  }

  p {
    color: var(--primary-lighter);
    cursor: pointer;
    font-size: 1rem;
    margin-right: 20px;
  }

  & > form {
    display: flex;
    flex-wrap: wrap;
  }

  & > form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & > input {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 96%;
      border: 1px solid var(--primary-lighter);
      border-radius: 6px;
      color: var(--primary);
      font-size: 10px;
      margin-top: 4px;
      padding: 6px 8px 4px 4px;
      width: 200px;

      &:focus {
        box-shadow: 0 0 6px var(--primary-lighter);
      }
    }
    & > input::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    & > input::placeholder {
      color: var(--primary);
    }

    & > input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 0;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
    }

    option {
      text-transform: capitalize;
    }
  }

  & > div {
    margin-left: auto;
  }

  & > .value-indication {
    margin-left: 0;
    margin-right: 30px;

    & p {
      color: var(--white);
      cursor: default;
      white-space: nowrap;

      & span {
        color: var(--primary-lighter);
      }
    }
  }

  @media (max-width: 419px) {
    flex-direction: column;

    & > form:nth-of-type(1) > div {
      & > input[type="search"],
      & > input[type="number"] {
        /* width: 180px; */
      }

      & > input[type="search"]::-webkit-search-cancel-button {
        right: 14px;
      }
    }
  }
`;

export const Button = styled(ButtonLayout)<IButton>`
  background: ${(props) => props.background};
  align-self: flex-end;
  color: var(--black);
  font-weight: 600;
  margin-right: 16px;
  width: ${(props) => (props.wide ? props.wide : "130px")};
  white-space: nowrap;

  & > span > svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
