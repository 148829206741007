import styled from "styled-components";
import InputLayout from "../Input";
import ButtonLayout from "../Button";

interface IProfileSaveContainer {
  showing?: boolean;
}

interface IWidth {
  background?: string;
  wide?: string;
  bottom?: string;
  margin?: string;
  left?: string;
  right?: string;
  colors?: string;
  jcontent?: string;
  transparent?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  @media (max-width: 1024px) {
    height: auto;
    overflow: hidden;
  }
`;

export const PaddingContainer = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 2fr;
  grid-gap: 8px;

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
    margin-bottom: 64px;

    & > div {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: var(--white);
`;

export const ProfilePhotoContainer = styled.div<IWidth>`
  position: relative;
  width: ${(props) => (props.wide ? props.wide : "350px")};
  height: ${(props) => (props.wide ? "160px" : "300px")};
  background-color: var(--form-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 2px;

  & > canvas {
    display: none;
  }
`;

export const ProfilePhotoButton = styled.button<IWidth>`
  background-color: var(--white);
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  height: ${(props) => (props.wide ? props.wide : "auto")};
  /* cursor: pointer; */

  &:hover {
    & > div {
      opacity: 1;
    }
  }

  .imageProfile {
    background: var(--form-background);
    color: var(--white);
  }
`;

export const ProfilePhoto = styled.img<IWidth>`
  width: ${(props) => (props.wide ? props.wide : "250px")};
  height: ${(props) => (props.wide ? props.wide : "250px")};
  border-radius: 50%;
  object-fit: contain;

  @media (max-width: 320px) {
    /* width: 90%; */
  }

  @media (max-width: 300px) {
    height: 200px;
  }
`;

export const ProfilePhotoButtonOverlay = styled.div`
  background-color: var(--white-opacity-background);
  border-radius: 125px;
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.2s;
  width: 250px;

  font-weight: 600;
  font-size: 12px;
  /* color: var(--primary-text-color); */

  & > input {
    display: none;
  }

  & > label {
    color: var(--black) !important;
    cursor: pointer;
    padding: 114px 50px;
  }
`;

export const ProfileInfoContainer = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--primary);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p {
      color: var(--white);
      font-size: 18px;
      font-weight: 300;
    }

    & > button {
      background-color: transparent;
      color: var(--white);
      cursor: pointer;
      font-weight: 300;
    }
  }

  & > hr {
    border-bottom: 1px solid var(--primary);
    box-shadow: 0 0 6px var(--primary-lighter);
    margin: 16px 0;
  }

  & > fieldset {
    margin-bottom: 8px;

    & > span {
      color: var(--muted);
      font-size: 14px;
      font-weight: 300;
    }

    & > p {
      color: var(--white);
    }
  }
`;

export const ProfileTokens = styled(ProfileInfoContainer)`
  margin-top: 6px;
  margin-left: auto;
  width: 100%;
`;

export const ProfileSaveContainer = styled.div<IProfileSaveContainer>`
  background-color: var(--form-background);
  /* position: absolute; */
  bottom: ${(props) => (props.showing ? 0 : "-100vh")};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--primary);
  transition: 0.2s;

  & > button {
    width: 100px;

    &:last-child {
      background-color: transparent;
      color: var(--primary-text-color);
      margin-left: 8px;
    }
  }
`;

export const Form = styled.form``;

export const Row = styled.div<IWidth>`
  display: flex;
  align-items: baseline;
  justify-content: ${(props) =>
    props.jcontent ? props.jcontent : "flex-start"};
  width: ${(props) => (props.wide ? props.wide : "100%")};

  margin-bottom: ${(props) => (props.bottom ? props.bottom : "32px")};
  margin-left: ${(props) => (props.left ? props.left : "")};
  margin-right: ${(props) => (props.right ? props.right : "0")};

  & .signOut {
    background-color: transparent;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .green {
    color: var(--green);
  }

  .red {
    color: var(--warning);
  }

  & > .loading > svg {
    background: transparent;
    color: var(--primary);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 301px) {
    & > button:nth-of-type(1) {
      margin-right: 10px;
    }
  }
`;

export const Column = styled.div<IWidth>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.wide ? props.wide : "100%")};

  .token {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .d-flex {
    display: flex;
    width: 70%;
  }

  label {
    color: var(--white);
    margin-bottom: 4px;
  }

  @media (max-width: 599px) {
    width: 100%;

    .d-flex {
      width: 100%;
    }

    & > .token > button {
      margin-top: 16px;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    & > div:nth-of-type(4) {
      justify-content: center;
    }
  }
`;

export const RowInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;

  .finished {
    margin-top: 30px;
    width: 50%;
  }

  .finished > div > input {
    margin-right: 4px;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-left: auto;
    width: 50%;
  }

  @media (max-width: 1239px) {
    align-items: center;
    .finished {
      width: 100%;
    }
    & > div:nth-of-type(2) {
      margin-top: 10px;
    }
  }

  @media (max-width: 1024px) {
    align-items: baseline;
    .finished {
      width: 50%;
    }
    & > div:nth-of-type(2) {
      margin-top: 0;
    }
  }

  @media (max-width: 788px) {
    align-items: center;
    .finished {
      width: 100%;
    }
    & > div:nth-of-type(2) {
      margin-top: 10px;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;

    & .finished {
      width: 100%;
    }

    & > button {
      width: 100% !important;
      margin-top: 16px;
    }
  }

  @media (max-width: 389px) {
    & > div:nth-of-type(2) {
      flex-wrap: wrap;
      width: 100%;

      & > button:nth-of-type(1) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`;

export const Input = styled.input<IWidth>`
  border-radius: 10px;
  border: 1px solid var(--primary);
  background: transparent !important;
  height: 40px;
  font-size: 16px;
  color: var(--white) !important;
  padding: 0px 8px 0px 8px;
  margin-right: 10px;
  width: ${(props) => (props.wide ? props.wide : "100%")};

  &:focus {
    background: transparent;
    box-shadow: 0 0 6px var(--primary-lighter);
  }

  @media (max-width: 599px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const Select = styled.select<IWidth>`
  border: 1px solid var(--primary);
  border-radius: 10px;
  background: var(--form-background);
  color: var(--primary);
  height: 40px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
  margin-right: 10px;
  width: ${(props) => (props.wide ? props.wide : "100%")};

  &:focus {
    box-shadow: 0 0 6px var(--primary-lighter);
  }

  @media (max-width: 599px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const Button = styled(ButtonLayout)<IWidth>`
  background: ${(props) =>
    props.background ? props.background : "var(--primary)"};
  color: var(--black);
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  margin-left: ${(props) => (props.margin ? props.margin : "")};
`;

export const ButtonOutline = styled.button<IWidth>`
  background: transparent;
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  margin-left: ${(props) => (props.margin ? props.margin : "")};
  margin-right: ${(props) => (props.right ? props.right : "")};
  outline: 2px solid var(--primary);
  outline-offset: -2px;
  padding: 10px;
  text-transform: uppercase;
  width: ${(props) => (props.wide ? props.wide : "100px")};
  white-space: nowrap;

  transition: 0.2s;
  -webkit-transition: 0.2s;

  :hover {
    background: var(--primary);
    color: var(--black);
    font-weight: 600;
  }
`;

export const ButtonSvg = styled.button<IWidth>`
  & > svg {
    background: ${(props) =>
      props.transparent ? "transparent" : "var(--white)"};
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: ${(props) => (props.transparent ? "var(--white)" : "var(--black)")};
    display: ${(props) => (props.transparent ? "none" : "block")};
    font-size: 40px;
    margin-left: -50px;
  }
`;

export const CardImage = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 30%;
  z-index: 4;
  padding: 4px;
  width: 100%;

  & > canvas {
    height: 100% !important;
    width: 100% !important;
  }

  & > div:nth-of-type(1) {
    /* background: var(--white); */
  }

  & > div > .ReactCrop__child-wrapper > img {
    max-width: 100%;
    min-width: 250px;
  }

  @media (max-width: 1024px) {
    left: 0;
    max-width: 400px;
  }

  @media (max-width: 330px) {
    & > div:nth-of-type(2) {
      width: 96%;
    }
  }
`;
