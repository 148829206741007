import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import { orderCols } from "../../utils/form";
import { IResponse } from "../Orders";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import { Button, Container, Content, Row, Title } from "./style";
import useFetch from "../../hooks/useFetch";
import { statusUser } from "../../services/user";
import Toast from "../../components/Toast";
import { AiOutlineLoading } from "react-icons/ai";

interface IUser {
  endpoint: string;
  filters?: object;
  multipleSelect: boolean;
}

const User: React.FC<IUser> = ({ endpoint, filters, multipleSelect }) => {
  const provider = useFetch({ endpoint });
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [users, setUsers] = useState<IResponse | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);

  useEffect(() => {
    getTableFields();
  }, [currentPage, quantityPerPage]);

  const getTableFields = async () => {
    try {
      setLoading(true);
      const result = await fields.get(filters);

      if (result) {
        // let filterFields = result.filter(
        //   (item: any) => item.campo !== "Classe"
        // );

        const user = result.sort(orderCols);

        setCols(user);

        const resultUsers = await provider.get({
          page: currentPage,
          ...filters,
          take: quantityPerPage,
        });
        if (resultUsers) {
          setUsers(resultUsers);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const handleActive = async (itens: any) => {
    try {
      setLoading(true);
      const result = await statusUser(itens);
      if (result.status === 201) {
        getTableFields();
        Toast.show("Alterado com sucesso.", "success");
      }
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const indexSelected = () => {
    if (users) {
      let itens: any = [];
      let docs = [...users?.docs];
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item === true) {
          let ativo = docs[index].excluido;
          docs[index].excluido = !ativo;

          itens.push(docs[index].id);
          // selection.splice(index, 1);
        }
      });

      if (itens.length > 0) {
        handleActive(itens);
      }
    }
  };

  function isTrue(item: any) {
    return item == false;
  }

  return (
    <Container>
      <Title>Usuários</Title>
      <Row>
        {loading && (
          <span>
            <AiOutlineLoading />
          </span>
        )}
        <Button
          type="button"
          onClick={() => indexSelected()}
          disabled={selectedIndexes.every(isTrue) || loading == true}
        >
          Alterar Status
        </Button>
      </Row>
      <Content>
        <ListTable
          data={users ? users.docs : []}
          multipleSelect={multipleSelect}
          fields={cols}
          changeSelectedIndexes={setSelectedIndexes}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={users ? users.totalPages : 1}
          count={users ? users.total : 0}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={users ? users.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default User;
