import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  FormContainer,
  Input,
  Link,
  Logo,
  Row,
  Subtitle,
  Title,
} from "./styles";
import LogoImage from "../../assets/images/logo_branco.png";
import { AiOutlineLoading } from "react-icons/ai";
import Toast from "../../components/Toast";
import { forgotPassword } from "../../services/auth";

const ConfirmEmail: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");

  const handlePayload = (value: string) => {
    setEmail(value.toLowerCase());
  };

  const validation = () => {
    if (email === "") {
      Toast.show("Digite o seu email", "error");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      Toast.show("Este endereço de e-mail é inválido", "error");
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (validation()) {
      setLoading(true);
      try {
        const result = await forgotPassword(email);
        if (result.status == 201) {
          Toast.show("Enviado com sucesso", "success");
          Toast.show("Verifique seu email", "success");
          history.push("/login");
          setLoading(false);
        }
      } catch (err: any) {
        Toast.show(err.message, "error");
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Recuperar Senha</Title>
        <Subtitle>Redefinir senha</Subtitle>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={email}
            onChange={({ target }) => handlePayload(target.value)}
          />
          <Button
            disabled={loading}
            variant="filled"
            type="button"
            onClick={() => handleSubmit()}
          >
            Enviar &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
          <Row>
            <Link variant="outlined" to={{ pathname: "/login" }}>
              Login
            </Link>
            <Link variant="outlined" to={{ pathname: "/cadastro" }}>
              Cadastre-se
            </Link>
          </Row>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default ConfirmEmail;
